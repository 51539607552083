/**
 * EliorGroup.Joyfood.B2B API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type B2BDays = null | null | null | null | null | null | null;

export const B2BDays = {
    NUMBER_null: null as B2BDays,
    NUMBER_null2: null as B2BDays,
    NUMBER_null3: null as B2BDays,
    NUMBER_null4: null as B2BDays,
    NUMBER_null5: null as B2BDays,
    NUMBER_null6: null as B2BDays,
    NUMBER_null7: null as B2BDays
};

